import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { Participant } from '../../types/v3Types';
import { isSelfPaced } from '../isSelfPaced';
import { getProgress } from './getProgress';

export function isProgramProgressReachedEnd(
  challenge: Challenge,
  participant: Participant,
  isScheduledExperimentEnabled: boolean = false,
) {
  return (
    !!challenge &&
    (isScheduledExperimentEnabled || isSelfPaced(challenge)) &&
    getProgress(participant) === 100
  );
}
